"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = isMobile;
function isMobile() {
    return (isMobile.android() ||
        isMobile.blackberry() ||
        isMobile.ios() ||
        isMobile.opera() ||
        isMobile.windows());
}
isMobile.android = () => {
    if (typeof navigator === 'undefined')
        return false;
    return !!navigator.userAgent.match(/Android/i);
};
isMobile.blackberry = () => {
    if (typeof navigator === 'undefined')
        return false;
    return !!navigator.userAgent.match(/BlackBerry/i);
};
isMobile.ios = () => {
    if (typeof navigator === 'undefined')
        return false;
    return (!!navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
        /^iP/.test(navigator.platform) ||
        (/^MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 4));
};
isMobile.opera = () => {
    if (typeof navigator === 'undefined')
        return false;
    return !!navigator.userAgent.match(/Opera Mini/i);
};
isMobile.windows = () => {
    if (typeof navigator === 'undefined')
        return false;
    return !!navigator.userAgent.match(/IEMobile/i);
};
