"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("../style");
const InputOptionsItem = (0, react_1.forwardRef)(function InputOptionsItem(props, ref) {
    const { value, title, data, inputId, selected, active, renderer, onClick, onMouseEnter, onMouseLeave, } = props;
    const handleOnClick = () => {
        onClick({ value, title, data });
    };
    const handleOnMouseEnter = () => {
        onMouseEnter(value);
    };
    const handleOnMouseLeave = () => {
        onMouseLeave();
    };
    return ((0, jsx_runtime_1.jsxs)(style_1.$InputOptionsItem, { "data-component": "InputOptionsItem", "data-input-id": inputId, "data-input-value": value, ref: ref, onClick: handleOnClick, onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave, "$selected": selected, "$active": active, children: [renderer && renderer({ id: inputId, option: { value, title, data } }), !renderer && title] }));
});
exports.default = InputOptionsItem;
