"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("../style");
const InputSelectNative = (0, react_1.forwardRef)(function InputSelectNative(props, ref) {
    const { id, value, size = 'm', error = false, disabled = false, clearable = false, onFocus = () => null, onBlur = () => null, onChange = () => null, focused = false, withLabel = false, textAlign = 'left', options, } = props;
    return ((0, jsx_runtime_1.jsxs)(style_1.$InputSelectNative, { as: "select", ref: ref, id: id, value: value || '', disabled: disabled, onFocus: onFocus, onBlur: onBlur, onChange: onChange, "$size": size, "$disabled": disabled, "$focused": focused, "$error": error, "$withLabel": withLabel, "$textAlign": textAlign, "$withIcon": true, children: [clearable && (0, jsx_runtime_1.jsx)("option", { value: "" }), options.map((item) => ((0, jsx_runtime_1.jsx)("option", { value: item.value, children: item.title }, item.value)))] }));
});
exports.default = InputSelectNative;
